.qmgx702 {
  display: block;
  align-items: center;
  width: fit-content;
}
.qmgx704 {
  --qmgx701: rgba(203,208,223,0.3);
}
.qmgx705 {
  --qmgx701: #2A579C;
}
.qmgx706 {
  --qmgx701: #2A579C;
}
.qmgx707 {
  --qmgx701: #3F6DB4;
}
.qmgx708 {
  --qmgx701: #FF696D;
}
.qmgx709 {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.qmgx70a {
  cursor: default;
  opacity: 0.5;
}
.qmgx70b {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0d1331;
  white-space: nowrap;
  padding-left: 5px;
}
.qmgx70c {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.qmgx70d {
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 2px rgba(203,208,223,0.3);
  height: var(--qmgx700);
  width: var(--qmgx700);
  flex-shrink: 0;
}
.qmgx70d:hover {
  border: solid 2px var(--qmgx701);
}
.qmgx70d:after {
  content: "";
  position: absolute;
  display: none;
}
.qmgx70e {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--qmgx701);
  border-radius: 50%;
  height: var(--qmgx700);
  width: var(--qmgx700);
}
.qmgx70f {
  border-radius: 50%;
  background-color: var(--qmgx701);
  border: solid 1px var(--qmgx701);
  height: var(--qmgx700);
  width: var(--qmgx700);
}
.qmgx70g {
  position: absolute;
  top: 50%;
  left: 0.625em;
  transform: translateY(-50%);
  cursor: pointer;
}
.qmgx70c:hover input ~ .qmgx70d {
  border: solid 2px var(--qmgx701);
}
.qmgx70c:focus ~ .checkmark {
  border: solid 2px var(--qmgx701);
}
.qmgx70a:hover input ~ .qmgx70d {
  border: solid 2px rgba(203,208,223,0.3);
}
.qmgx70c .qmgx70e:after {
  display: block;
}
.qmgx70a:hover .qmgx70e {
  border: solid 2px rgba(203,208,223,0.3);
}