._1otoqc81 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 2px solid var(--_1otoqc80);
  border-radius: 8px;
  resize: both;
  overflow: auto;
}
._1otoqc82 {
  max-height: 280px;
  overflow: auto;
}
._1otoqc81 .ProseMirror {
  margin: 1em 0 2.125em 1em;
  padding-bottom: 5px;
}
._1otoqc81 .ProseMirror:focus {
  outline: transparent !important;
}
._1otoqc81 .ProseMirror p.is-editor-empty:first-of-type::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--vve2dmn);
  pointer-events: none;
  height: 0;
}