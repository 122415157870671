.y6tr80 {
  display: flex;
}
.y6tr80.--is-active {
  background-color: var(--vve2dmm);
}
.y6tr80 svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
.y6tr80 button {
  margin-left: 0.25em;
  padding: 0.5em !important;
}
.y6tr81 {
  width: 100%;
  background-color: var(--vve2dm8);
  position: static;
  bottom: 0;
  left: 0;
}
.y6tr81 .pq-button {
  display: flex;
  align-items: center;
  margin-left: 0.05em;
}
.y6tr81 .pq-button button {
  padding: 0.25em !important;
}
.y6tr82 {
  border-left: 2px outset var(--vve2dmm);
  height: 38px;
  margin: 0 10px;
}