._8kfx9e1 {
  width: 100%;
  height: 100%;
  align-items: center;
  display: var(--_8kfx9e0);
}
._8kfx9e4 {
  display: var(--_8kfx9e2);
  border-radius: 8px;
  border: solid 0px rgba(203,200,223, 0.3);
}
._8kfx9e4.-disabled {
  border-color: var(--vve2dml);
}
._8kfx9e5 {
  position: absolute;
  margin-left: 1rem;
  margin-top: -10px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  color: var(--vve2dmp);
  background-color: var(--vve2dm8);
  white-space: nowrap;
  z-index: 1;
  padding-left: 8px;
  padding-right: 8px;
}
._8kfx9e5.-disabled {
  border-color: var(--vve2dml);
}
._8kfx9e5[aria-required="true"]::after {
  content: " *";
  color: var(--vve2dm2);
}
._8kfx9e5.-disabled span {
  opacity: 0.5;
}
._8kfx9e6 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}