.b3lce92 {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  left: var(--b3lce90);
  top: var(--b3lce91);
  transform: rotate(45deg);
}
.b3lce93 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.b3lce94 {
  position: relative;
  min-width: 600px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}
.b3lce94 .pq-card__content {
  padding: 0.5em;
}
.b3lce95 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.b3lce96 {
  margin-left: 1em;
}
.b3lce97 {
  margin-bottom: 1em;
}
.b3lce98 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.b3lce99 {
  padding: 0;
}