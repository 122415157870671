@keyframes _1x3h7bt3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
._1x3h7bt4 {
  width: var(--_1x3h7bt0);
  height: var(--_1x3h7bt1);
  color: var(--_1x3h7bt2);
  border-radius: 50%;
  display: inline-block;
  border-top: 1px solid var(--_1x3h7bt2);
  border-right: 2px solid var(--_1x3h7bt2);
  border-bottom: 3px solid var(--_1x3h7bt2);
  border-left: 4px solid transparent;
  box-sizing: border-box;
  animation: _1x3h7bt3 1s linear infinite;
}