:root {
  --vve2dm0: #2A579C;
  --vve2dm1: #3F6DB4;
  --vve2dm2: #F1565B;
  --vve2dm3: #3F6DB4;
  --vve2dm4: #FF696D;
  --vve2dm5: rgba(255, 105, 109, 0.5);
  --vve2dm6: #0d1331;
  --vve2dm7: rgba(203, 208, 223, 0.3);
  --vve2dm8: #fff;
  --vve2dm9: #CBD0DF;
  --vve2dma: #D6AC56;
  --vve2dmb: #4ABEDA;
  --vve2dmc: #5387D7;
  --vve2dmd: #6366C7;
  --vve2dme: #8a6326;
  --vve2dmf: #FFF5F5;
  --vve2dmg: #FFCACC;
  --vve2dmh: #FF8B8E;
  --vve2dmi: #FF696D;
  --vve2dmj: #F1565B;
  --vve2dmk: #FBFBFB;
  --vve2dml: #F4F4F7;
  --vve2dmm: #E9EAF0;
  --vve2dmn: #CBD0DF;
  --vve2dmo: #B7BCCB;
  --vve2dmp: #8A91A5;
  --vve2dmq: #4B526B;
  --vve2dmr: #EFF1F5;
  --vve2dms: #EFF3F9;
  --vve2dmt: #DDE9FB;
  --vve2dmu: #7A9DD6;
  --vve2dmv: #3F6DB4;
  --vve2dmw: #2A579C;
  --vve2dmx: #F1FBF7;
  --vve2dmy: #A9E8D0;
  --vve2dmz: #10AC6F;
  --vve2dm10: #00905B;
  --vve2dm11: #FFFAEB;
  --vve2dm12: #F7E4A8;
  --vve2dm13: #F3A40A;
  --vve2dm14: #E38800;
  --vve2dm15: #0D1331;
}
html, body {
  width: 100%;
  scroll-behavior: smooth;
}
a {
  text-decoration: underline;
  color: var(--vve2dmw);
}