._14c5fb82 {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  left: var(--_14c5fb80);
  top: var(--_14c5fb81);
  transform: rotate(45deg);
}
._14c5fb83 {
  background-color: rgba(5, 15, 31, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  place-items: center;
  z-index: 2000;
}