.k57oht0 {
  display: flex;
  align-items: center;
  width: fit-content;
}
.k57oht1 {
  display: flex;
  align-items: center;
  font-size: 0.813rem;
  font-weight: normal;
  line-height: 24px;
  color: #0D1331;
  padding-left: 2.5em;
}
.k57oht2 {
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
}
.k57oht3 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.k57oht3:disabled {
  cursor: auto;
}
.k57oht3:disabled:hover ~ .pq-form__checkmark {
  border: solid 2px rgba(203, 208, 223, 0.3);
}
.k57oht3:focus ~ .pq-form__checkmark {
  border: solid 2px #3f6db4;
}
.k57oht3:hover ~ .pq-form__checkmark {
  border: solid 2px #3f6db4;
}
.k57oht3:checked ~ .pq-form__checkmark {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: solid 2px #3f6db4;
  background-color: #FFFFFF;
}
.k57oht3:not(:checked) ~ .pq-form__checkmark {
  border: solid 2px rgba(203, 208, 223, 0.3);
}
.k57oht3:checked ~ .pq-form__checkmark:after {
  display: block;
}
.k57oht4 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border-color: rgba(203, 208, 223, 0.3);
  border-width: 2px;
  border-style: solid;
}
.k57oht4:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 5px;
  height: 8px;
  border: solid #3F6DB4;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}