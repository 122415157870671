.ek10cy1 {
  margin-left: 10px;
}
.ek10cy2 {
  display: inline-flex;
  position: relative;
  align-items: center;
  border-radius: 8px;
  padding: 7px 21px;
  font-weight: 600;
  width: var(--ek10cy0);
  justify-content: center;
  border: 2px solid transparent;
  cursor: pointer;
}
.ek10cy2:disabled {
  background-color: #f4f4f4f7;
  color: #b7bccb;
  cursor: auto;
}
.ek10cy2:disabled svg, .ek10cy2:disabled .pq-spinner {
  opacity: 0.4;
  filter: invert(77%) sepia(11%) saturate(284%) hue-rotate(187deg) brightness(98%) contrast(87%);
}
.ek10cy2 svg {
  position: relative;
  min-height: 1em;
  min-width: 1em;
  display: inline-block;
}
.ek10cy3 {
  color: #ffffff;
  background-color: #2a579c;
}
.ek10cy3:focus:not([disabled]) {
  background-color: #3f6db4;
}
.ek10cy3:hover:not([disabled]) {
  background-color: #3f6db4;
}
.ek10cy3:active:not([disabled]) {
  background-color: #7a9dd6;
}
.ek10cy4 {
  color: #ffffff;
  background-color: #f1565b;
}
.ek10cy4:not([disabled]) {
  box-shadow: 0 4px 12px 0 rgba(239, 39, 45, 0.3);
}
.ek10cy4:focus:not([disabled]) {
  background-color: #ff696d;
}
.ek10cy4:hover:not([disabled]) {
  background-color: #ff696d;
}
.ek10cy4:active:not([disabled]) {
  background-color: #ff8b8e;
}
.ek10cy5 {
  color: #3f6db4;
  background-color: #eff3f9;
}
.ek10cy5:focus:not([disabled]) {
  color: #2a579c;
  background-color: #dde9fb;
}
.ek10cy5:hover:not([disabled]) {
  color: #2a579c;
  background-color: #dde9fb;
}
.ek10cy5:active:not([disabled]) {
  color: #7a9dd6;
  background-color: #dde9fb;
}
.ek10cy6 {
  color: #2a579c;
  background-color: #eff3f9;
  height: 100%;
}
.ek10cy7 {
  color: #ff696d;
  background-color: #ffebeb;
}
.ek10cy7:hover:not([disabled]) {
  color: #f1565b;
  background-color: #ffcacc;
}
.ek10cy7:active:not([disabled]) {
  color: #ff8b8e;
  background-color: #ffcacc;
}
.ek10cy8 {
  color: #2a579c;
  background-color: transparent;
  padding: 0 !important;
}
.ek10cy8:hover:not([disabled]) {
  opacity: 0.8;
}
.ek10cy9 {
  color: #f1565b;
  background-color: transparent;
  padding: 0 !important;
}
.ek10cy9:hover:not([disabled]) {
  opacity: 0.8;
}
.ek10cya {
  color: #f3a40a;
  background-color: transparent;
  padding: 0 !important;
}
.ek10cya:hover:not([disabled]) {
  opacity: 0.8;
}
.ek10cyb {
  color: #8a91a5;
  background-color: #f4f4f7;
}
.ek10cyb:disabled {
  opacity: 0.9 !important;
}
.ek10cyc {
  color: #42bb8b;
}
.ek10cyd {
  background-color: transparent;
}
.ek10cyd:hover:not([disabled]) {
  background-color: #f4f4f7;
}
.ek10cyf {
  color: #0d1331;
  background-color: #ffffff !important;
}
.ek10cyf:focus:not([disabled]) {
  background-color: #eff1f5;
}
.ek10cyf:hover:not([disabled]) {
  background-color: #eff1f5;
}
.ek10cyg {
  font-size: 0.75rem;
  padding: 4px 10px;
  border-radius: 6px;
}
.ek10cyh {
  font-size: 0.875rem;
  padding: 8px 16px;
}
.ek10cyi {
  font-size: 0.973rem;
  padding: 12px 24px;
}