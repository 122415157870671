._70guy70 {
  position: absolute;
  margin-left: 1em;
  margin-top: -0.625em;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  color: #8a91a5;
  background-color: var(--vve2dm8);
  white-space: nowrap;
  padding: 0 0.5em;
  opacity: 1;
  z-index: 1;
}
._70guy70.-required:after {
  content: " *";
  color: #ff696d;
}