._17uo4lh0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
}
._17uo4lh3 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 124px;
  height: 104px;
  position: relative;
  border-radius: 8px;
  outline: solid 1px rgba(203,208,223,0.3);
  cursor: var(--_17uo4lh2);
  background-color: #ffffff;
  line-height: 104px;
  padding: 1px;
  flex: 1;
  box-sizing: border-box;
  margin-right: 15px;
}
._17uo4lh3:hover {
  outline: solid var(--_17uo4lh1);
}
._17uo4lh4 {
  max-width: 94px;
  max-height: 94px;
  object-fit: cover;
  border-radius: 5px;
  border: solid 1px rgba(203, 208, 223, 0.3);
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
._17uo4lh6 {
  padding: 10px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  border: solid 2px var(--_17uo4lh5);
}
._17uo4lh7 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #3a3a3a;
  margin-bottom: 5px;
}
._17uo4lh8 {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #8a91a5;
  text-align: left;
}
._17uo4lh9 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: var(--_17uo4lh2);
}
._17uo4lha {
  width: 96px;
  height: 18px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #3f6db4;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 12px;
}
._17uo4lhb {
  margin-right: 10px;
}
._17uo4lhc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._17uo4lhd {
  font-family: Poppins;
  font-size: 12px;
  color: var(--primaryRed);
  float: right;
  font-weight: 600;
}