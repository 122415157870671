._10yvvnl1 {
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(25,25,25,0.16);
  color: var(--vve2dm6);
  border-radius: 0.5em;
  white-space: pre-line;
  word-break: break-all;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  border: none;
  padding: 12px;
}
._10yvvnl2 {
  cursor: var(--_10yvvnl0);
}