.kcsys60 {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
.kcsys61 {
  padding: 1em;
  border-radius: 5px;
  display: flex;
}
.kcsys62 {
  display: flex;
  flex-direction: column;
}
.kcsys63 {
  color: var(--vve2dmi);
  background-color: var(--vve2dmf);
  border: 1px solid var(--vve2dmi);
}
.kcsys64 {
  color: var(--vve2dmz);
  background-color: var(--vve2dmx);
  border: 1px solid var(--vve2dmz);
}
.kcsys65 {
  color: var(--vve2dmv);
  background-color: var(--vve2dms);
  border: 1px solid var(--vve2dmv);
}
.kcsys66 {
  color: var(--vve2dm14);
  background-color: var(--vve2dm11);
  border: 1px solid var(--vve2dm14);
}